.App {
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.TopBar {
    /* Auto layout */
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    align-items: center;
    padding: 16px 60px;
    gap: 10px;
    height: 55px;
    /* Blue */
    background: #2A3A7E;
    /* Inside auto layout */
    flex: none;
    order: 0;
    align-self: stretch;
    flex-grow: 0;
}

.TopBarContact {
    height: 23px;
    font-family: 'Source Sans Pro';
    font-style: normal;
    font-weight: 700;
    font-size: 18px;
    line-height: 23px;
    /* identical to box height */
    display: flex;
    align-items: center;
    text-align: center;
    letter-spacing: 0.1em;
    text-transform: uppercase;
    /* White */
    color: #FFFFFF;
    /* Inside auto layout */
    flex: none;
    order: 1;
    flex-grow: 0;
}

.TopBarPhoneNumber {
    height: 23px;
    font-family: 'Source Sans Pro';
    font-style: normal;
    font-weight: 700;
    font-size: 18px;
    line-height: 23px;
    /* identical to box height */
    display: flex;
    align-items: center;
    text-align: center;
    letter-spacing: 0.1em;
    text-transform: uppercase;
    /* White */
    color: #FFFFFF;
    /* Inside auto layout */
    flex: none;
    order: 1;
    flex-grow: 0;
    margin-left: 20px;
}

.HeaderNav {
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 16px 72px;
    /* gap: 48px; */
    height: 107px;
    /* White */
    background: #FFFFFF;
    /* Inside auto layout */
    flex: none;
    order: 1;
    align-self: stretch;
    justify-content: space-between;
    flex-grow: 0;
}

.Logo {
    width: 166.76px;
    height: 75px;
    /* Inside auto layout */
    flex: none;
    order: 0;
    flex-grow: 0;
    cursor: pointer;
}

.Navigation {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    align-items: center;
    padding: 0px;
    gap: 48px;
    width: 1110px;
    height: 50px;
    /* Inside auto layout */
    flex: none;
    order: 1;
    flex-grow: 0;
}

.NavLink {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    padding: 10px;
    gap: 10px;
    height: 43px;
    /* Inside auto layout */
    flex: none;
    order: 0;
    flex-grow: 0;
}

.NavLinkText {
    font-family: 'Source Sans Pro';
    font-style: normal;
    font-weight: 700;
    font-size: 18px;
    line-height: 23px;
    /* identical to box height */
    text-align: center;
    letter-spacing: 0.1em;
    text-transform: uppercase;
    /* Orange */
    color: #E57937;
    /* Inside auto layout */
    flex: none;
    order: 0;
    flex-grow: 0;
    cursor: pointer;
}

.ButtonPrimary {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 0px 24px;
    height: 50px;
    /* Teal */
    background: #E57937;
    /* Inside auto layout */
    flex: none;
    order: 4;
    flex-grow: 0;
    font-family: 'Cinzel', serif;
    font-style: normal;
    font-weight: 700;
    font-size: 21px;
    line-height: 28px;
    display: flex;
    align-items: center;
    text-align: center;
    text-transform: uppercase;
    /* White */
    color: #FFFFFF;
    cursor: pointer;
}

.ButtonPrimary:hover {
    background: #2A3A7E;
}

.ButtonSecondary {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 0px 24px;
    height: 50px;
    /* Teal */
    background: #2C97B2;
    /* Inside auto layout */
    flex: none;
    order: 4;
    flex-grow: 0;
    font-family: 'Cinzel', serif;
    font-style: normal;
    font-weight: 700;
    font-size: 21px;
    line-height: 28px;
    display: flex;
    align-items: center;
    text-align: center;
    text-transform: uppercase;
    /* White */
    color: #FFFFFF;
    cursor: pointer;
}

.ButtonSecondary:hover {
    background: #FFFFFF;
    border: 2px solid #2C97B2;
    color: #2C97B2;
    padding: 0px 22px;
}

.HeroImageContainer {
    height: 680px;
    /* Inside auto layout */
    flex: none;
    order: 2;
    align-self: stretch;
    flex-grow: 0;
    background-size: cover;
}

.HeroImageContent {
    /* Auto layout */
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 0px;
    gap: 32px;
    position: absolute;
    width: 550px;
    height: 478px;
    left: 99px;
    top: 265px;
}

.HeroCopy {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 0px;
    gap: 16px;
    width: 550px;
    height: 386px;
    /* Inside auto layout */
    flex: none;
    order: 0;
    flex-grow: 0;
}

.HeroCopyCallout {
    width: 550px;
    height: 38px;
    /* Heading/Preheading */
    font-family: 'Source Sans Pro';
    font-style: normal;
    font-weight: 700;
    font-size: 30px;
    line-height: 38px;
    /* identical to box height */
    display: flex;
    align-items: center;
    letter-spacing: 0.1em;
    text-transform: uppercase;
    /* Orange */
    color: #E57937;
    /* Inside auto layout */
    flex: none;
    order: 0;
    flex-grow: 0;
}

.HeroCopyHeading {
    width: 550px;
    height: 124px;
    /* Heading/Title 01 */
    font-family: 'DM Serif Text';
    font-style: normal;
    font-weight: 400;
    font-size: 50px;
    line-height: 60px;
    /* or 120% */
    display: flex;
    align-items: center;
    /* White */
    color: #FFFFFF;
    /* Inside auto layout */
    flex: none;
    order: 1;
    flex-grow: 0;
}

.HeroCopyBody {
    width: 550px;
    height: 192px;
    /* Body/Body 01 */
    font-family: 'Archivo';
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    line-height: 180%;
    /* or 32px */
    display: flex;
    align-items: center;
    /* White */
    color: #FFFFFF;
    /* Inside auto layout */
    flex: none;
    order: 2;
    align-self: stretch;
    flex-grow: 0;
}

.StatsBar {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 32px 79px;
    gap: 24px;
    height: 161.5px;
    /* Teal */
    background: #2C97B2;
    /* Inside auto layout */
    flex: none;
    order: 3;
    align-self: stretch;
    flex-grow: 0;
}

.StatsBarItem {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 0px;
    gap: 3px;
    width: 162.57px;
    height: 78px;
    /* Inside auto layout */
    flex: none;
    order: 0;
    flex-grow: 1;
}

.StatsBarNumber {
    height: 50px;
    /* Heading/Title 02 */
    font-family: 'DM Serif Text';
    font-style: normal;
    font-weight: 400;
    font-size: 40px;
    line-height: 50px;
    /* identical to box height, or 125% */
    display: flex;
    align-items: center;
    text-align: center;
    /* White */
    color: #FFFFFF;
    /* Inside auto layout */
    flex: none;
    order: 0;
    flex-grow: 0;
}

.StatsBarLabel {
    font-family: 'Source Sans Pro';
    font-style: normal;
    font-weight: 700;
    font-size: 20px;
    line-height: 25px;
    display: flex;
    align-items: center;
    text-align: center;
    letter-spacing: 0.1em;
    text-transform: uppercase;
    /* White */
    color: #FFFFFF;
    /* Inside auto layout */
    flex: none;
    order: 1;
    flex-grow: 0;
}

.IntroBlock {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 60px 80px;
    gap: 20px;
    height: 603.51px;
    /* Inside auto layout */
    flex: none;
    order: 4;
    align-self: stretch;
    flex-grow: 0;
}

.Icon {
    flex: none;
    order: 0;
    flex-grow: 0;
}

.IntroBlockCallout {
    /* Callout */
    height: 38px;
    width: 1280px;
    /* Heading/Preheading */
    font-family: 'Source Sans Pro';
    font-style: normal;
    font-weight: 700;
    font-size: 30px;
    line-height: 38px;
    text-align: center;
    letter-spacing: 0.1em;
    text-transform: uppercase;
    /* Orange */
    color: #E57937;
}

.IntroBlockHeading {
    /* Heading */
    width: 1280px;
    height: 50px;
    /* Heading/Title 02 */
    font-family: 'DM Serif Text';
    font-style: normal;
    font-weight: 400;
    font-size: 40px;
    line-height: 50px;
    text-align: center;
    /* Blue */
    color: #2A3A7E;
}

.IntroBlockBody {
    /* Body */
    width: 1280px;
    height: 128px;
    /* Body/Body 01 */
    font-family: 'Archivo';
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    line-height: 180%;
    text-align: center;
    /* Dark Gray */
    color: #434343;
}

.CTAButtons {
    /* Auto layout */
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: flex-start;
    text-align: center;
    padding: 24px 0px;
    gap: 32px;
    height: 108px;
    /* Inside auto layout */
    flex: none;
    order: 4;
    align-self: stretch;
    flex-grow: 0;
}

.Spacer {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 60px 80px;
    gap: 20px;
    width: 1440px;
    height: 200px;
    /* Inside auto layout */
    flex: none;
    order: 5;
    flex-grow: 0;
}

.DonateContainer {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    padding: 0px 100px;
    gap: 10px;
    height: 645px;
    /* Inside auto layout */
    flex: none;
    order: 6;
    align-self: stretch;
    flex-grow: 0;
    background-size: cover;
}

.DonateContent {
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 0px;
    gap: 157px;
    isolation: isolate;
    width: 1240px;
    height: 349px;
    /* Inside auto layout */
    flex: none;
    order: 0;
    align-self: stretch;
    flex-grow: 0;
}

.DonateContentCopy {
    /* Copy */
    /* Auto layout */
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    padding: 0px 24px;
    gap: 16px;
    width: 620px;
    height: 349px;
    /* Inside auto layout */
    flex: none;
    order: 1;
    align-self: stretch;
    flex-grow: 0;
    z-index: 1;
}

.DonateDonationFormContainer {
    /* Donation Form */
    box-sizing: border-box;
    /* Auto layout */
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    padding: 45px 48px;
    gap: 10px;
    width: 482px;
    position: absolute;
    height: 600px;
    left: 900px;
    top: 1830px;
    /* White */
    background: #FFFFFF;
    /* Medium Gray */
    border: 1px solid #C4C4C4;
    box-shadow: 4px 0px 10px rgba(0, 0, 0, 0.15);
    /* Inside auto layout */
    flex: none;
    order: 0;
    flex-grow: 0;
    z-index: 0;
}

.DonateDonationFormContainerContent {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 0px;
    gap: 50px;
    width: 386px;
    height: 587px;
    /* White */
    background: #FFFFFF;
    /* Inside auto layout */
    flex: none;
    order: 0;
    flex-grow: 0;
}

.DonateDonationFormContainerContentPayment {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 0px;
    gap: 24px;
    width: 386px;
    height: 240px;
    /* Inside auto layout */
    flex: none;
    order: 0;
    flex-grow: 0;
}

.DonateDonationFormContainerContentPaymentPresetAmount {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 0px;
    gap: 16px;
    width: 386px;
    height: 168px;
    /* Inside auto layout */
    flex: none;
    order: 0;
    flex-grow: 0;
}

.DonateDonationFormContainerContentPaymentPresetAmountText {
    width: 386px;
    height: 32px;
    /* Body/Body 01 (Link) */
    font-family: 'Archivo';
    font-style: normal;
    font-weight: 600;
    font-size: 18px;
    line-height: 180%;
    /* or 32px */
    display: flex;
    align-items: center;
    /* Dark Gray */
    color: #434343;
    /* Inside auto layout */
    flex: none;
    order: 0;
    align-self: stretch;
    flex-grow: 0;
}

.DonateDonationFormContainerContentPaymentPresetAmountTextRow {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    padding: 0px;
    gap: 13px;
    width: 386px;
    height: 52px;
    /* Inside auto layout */
    flex: none;
    flex-grow: 0;
}

.DonateDonationFormContainerContentPaymentPresetAmountDonationButton {
    box-sizing: border-box;
    /* Auto layout */
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 8px;
    gap: 10px;
    width: 120px;
    height: 52px;
    /* Dark Gray */
    border: 2px solid #434343;
    /* Inside auto layout */
    flex: none;
    flex-grow: 0;
    cursor: pointer;
}

.DonateDonationFormContainerContentPaymentPresetAmountDonationButton:hover {
    border: 2px solid #2C97B2;
    color: #2C97B2;
    padding: 6px;
}

.DonateDonationFormContainerContentPaymentPresetAmountDonationButtonSelected {
    box-sizing: border-box;
    /* Auto layout */
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 8px;
    gap: 10px;
    width: 120px;
    height: 52px;
    /* Dark Gray */
    /* Inside auto layout */
    flex: none;
    flex-grow: 0;
    cursor: pointer;
    background: #2C97B2;
    color: #FFFFFF;
}

.DonateDonationFormContainerContentPaymentOther {
    box-sizing: border-box;
    /* Auto layout */
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 8px 16px;
    gap: 10px;
    width: 386px;
    height: 52px;
    /* Medium Gray */
    border: 1px solid #C4C4C4;
    /* Inside auto layout */
    flex: none;
    flex-grow: 0;
}

.DonateDonationFormContainerContentPaymentOtherInput {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding: 0px;
    gap: 10px;
    width: 297px;
    height: 36px;
    /* Inside auto layout */
    flex: none;
    flex-grow: 1;
}

.DonateDonationFormContainerContentCause {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 0px;
    gap: 8px;
    width: 386px;
    height: 88px;
    /* Inside auto layout */
    flex: none;
    flex-grow: 0;
}

.DonateDonationFormContainerContentCauseText {
    width: 386px;
    height: 32px;
    /* Body/Body 01 (Link) */
    font-family: 'Archivo';
    font-style: normal;
    font-weight: 600;
    font-size: 18px;
    line-height: 180%;
    /* or 32px */
    display: flex;
    align-items: center;
    /* Dark Gray */
    color: #434343;
    /* Inside auto layout */
    flex: none;
    order: 0;
    flex-grow: 0;
}

.DonateDonationFormContainerContentCauseDropdown {
    box-sizing: border-box;
    /* Auto layout */
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 8px 16px;
    gap: 10px;
    width: 386px;
    height: 48px;
    /* Medium Gray */
    border: 1px solid #C4C4C4;
    /* Inside auto layout */
    flex: none;
    flex-grow: 0;
}

.DonateDonationFormContainerContentCauseDropdownSelect {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding: 0px;
    gap: 10px;
    width: 354px;
    height: 32px;
    /* Inside auto layout */
    flex: none;
    flex-grow: 1;
}

.DonateContentCopyCallout {
    /* Callout */
    width: 572px;
    height: 47px;
    /* Heading/Preheading */
    font-family: 'Source Sans Pro';
    font-style: normal;
    font-weight: 700;
    font-size: 30px;
    line-height: 38px;
    display: flex;
    align-items: center;
    letter-spacing: 0.1em;
    text-transform: uppercase;
    /* Orange */
    color: #E57937;
    /* Inside auto layout */
    flex: none;
    order: 0;
    flex-grow: 0;
}

.DonateContentCopyHeading {
    /* Heading */
    width: 572px;
    height: 50px;
    /* Heading/Title 02 */
    font-family: 'DM Serif Text';
    font-style: normal;
    font-weight: 400;
    font-size: 40px;
    line-height: 50px;
    /* identical to box height, or 125% */
    display: flex;
    align-items: center;
    /* White */
    color: #FFFFFF;
    /* Inside auto layout */
    flex: none;
    order: 1;
    flex-grow: 0;
}

.DonateContentCopyBody {
    /* Body */
    width: 572px;
    height: 144px;
    /* Body/Body 02 */
    font-family: 'Archivo';
    font-style: normal;
    font-weight: 400;
    font-size: 20px;
    line-height: 180%;
    /* or 36px */
    display: flex;
    align-items: center;
    /* White */
    color: #FFFFFF;
    /* Inside auto layout */
    flex: none;
    order: 2;
    flex-grow: 0;
}

.DonateContentCopyVideoLink {
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 0px;
    gap: 24px;
    width: 266.41px;
    height: 60px;
    /* Inside auto layout */
    flex: none;
    order: 3;
    flex-grow: 0;
    cursor: pointer;
}

.DonateContentCopyVideoLinkText {
    width: 226px;
    height: 60px;
    /* Body/Body 02 (Link) */
    font-family: 'Archivo';
    font-style: normal;
    font-weight: 700;
    font-size: 20px;
    line-height: 60px;
    /* identical to box height, or 300% */
    display: flex;
    align-items: center;
    /* White */
    color: #FFFFFF;
    /* Inside auto layout */
    flex: none;
    order: 0;
    flex-grow: 0;
}

.Programs {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 48px 0px;
    gap: 40px;
    isolation: isolate;
    height: 835px;
    /* Inside auto layout */
    flex: none;
    order: 7;
    align-self: stretch;
    flex-grow: 0;
    background-image: linear-gradient(white, #F2F2F2);
}

.ProgramCards {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: flex-start;
    padding: 0px;
    gap: 40px;
    height: 739px;
    /* Inside auto layout */
    flex: none;
    flex-grow: 1;
    z-index: 1;
}

.ProgramCard {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 24px;
    gap: 24px;
    min-height: 739px;
    /* Inside auto layout */
    flex: none;
    flex-grow: 0;
}

.ProgramCardImage {
    width: 372px;
    height: 372px;
    /* Inside auto layout */
    flex: none;
    order: 0;
    flex-grow: 0;
}

.ProgramCardContent {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 0px;
    gap: 16px;
    width: 372px;
    height: 221px;
    /* Inside auto layout */
    flex: none;
    flex-grow: 0;
}

.ProgramCardContentTitle {
    width: 372px;
    height: 60px;
    /* Heading/Title 03 */
    font-family: 'DM Serif Text';
    font-style: normal;
    font-weight: 400;
    font-size: 36px;
    line-height: 60px;
    /* identical to box height, or 167% */
    display: flex;
    align-items: center;
    /* Blue */
    color: #2A3A7E;
    /* Inside auto layout */
    flex: none;
    flex-grow: 0;
}

.ProgramCardContentBody {
    width: 367px;
    height: 145px;
    /* Caption/Caption 02 */
    font-family: 'Archivo';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 180%;
    /* or 29px */
    display: flex;
    align-items: center;
    /* Dark Gray */
    color: #434343;
    /* Inside auto layout */
    flex: none;
    order: 1;
    flex-grow: 0;
}

.Form {
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 80px;
    gap: 60px;
    height: 533px;
    /* Inside auto layout */
    flex: none;
    order: 8;
    align-self: stretch;
    flex-grow: 0;
}

.FormContent {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    padding: 0px;
    gap: 20px;
    width: 680px;
    height: 242px;
    /* Inside auto layout */
    flex: none;
    flex-grow: 0;
}

.FormContentIcon {
    width: 64.38px;
    height: 64px;
    /* Inside auto layout */
    flex: none;
    order: 0;
    flex-grow: 0;
}

.FormContentCallout {
    width: 1280px;
    height: 38px;
    /* Heading/Preheading */
    font-family: 'Source Sans Pro';
    font-style: normal;
    font-weight: 700;
    font-size: 30px;
    line-height: 38px;
    /* identical to box height */
    display: flex;
    align-items: center;
    letter-spacing: 0.1em;
    text-transform: uppercase;
    /* Orange */
    color: #E57937;
    /* Inside auto layout */
    flex: none;
    order: 1;
    flex-grow: 0;
}

.FormContentHeading {
    width: 680px;
    height: 100px;
    /* Heading/Title 02 */
    font-family: 'DM Serif Text';
    font-style: normal;
    font-weight: 400;
    font-size: 40px;
    line-height: 50px;
    /* or 125% */
    display: flex;
    align-items: center;
    /* Blue */
    color: #2A3A7E;
    /* Inside auto layout */
    flex: none;
    order: 2;
    align-self: stretch;
    flex-grow: 0;
}

.FormContactForm {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-end;
    padding: 0px;
    gap: 10px;
    max-width: 540px;
    height: 373px;
    /* Inside auto layout */
    flex: none;
    flex-grow: 1;
}

.FormContactFormTextField {
    box-sizing: border-box;
    /* Auto layout */
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 8px 16px;
    gap: 10px;
    width: 540px;
    height: 48px;
    /* Medium Gray */
    border: 1px solid #C4C4C4;
    /* Inside auto layout */
    flex: none;
    align-self: stretch;
    flex-grow: 0;
}

.FormContactFormTextArea {
    box-sizing: border-box;
    /* Auto layout */
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    padding: 8px 16px;
    gap: 10px;
    width: 540px;
    height: 129px;
    /* Medium Gray */
    border: 1px solid #C4C4C4;
    /* Inside auto layout */
    flex: none;
    order: 3;
    align-self: stretch;
    flex-grow: 1;
}

.FormContactFormButton {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 0px 24px;
    width: 200px;
    height: 60px;
    /* Orange */
    background: #E57937;
    /* Inside auto layout */
    flex: none;
    order: 4;
    flex-grow: 0;
}

.Footer {
    box-sizing: border-box;
    /* Auto layout */
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 60px 80px 48px;
    gap: 240px;
    height: 326px;
    /* Dark Blue */
    background: #273261;
    /* Teal */
    border-top: 5px solid #2C97B2;
    /* Inside auto layout */
    flex: none;
    order: 9;
    align-self: stretch;
    flex-grow: 0;
}

.FooterLogoAndContact {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    padding: 0px;
    gap: 24px;
    width: 316px;
    height: 218px;
    /* Inside auto layout */
    flex: none;
    order: 0;
    flex-grow: 0;
}

.FooterSocialAndContact {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    padding: 0px;
    gap: 16px;
    width: 316px;
    height: 108px;
    /* Inside auto layout */
    flex: none;
    order: 1;
    flex-grow: 0;
}

.FooterSocialAndContactSocialIcons {
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 0px;
    gap: 27px;
    width: 159.65px;
    height: 20px;
    /* Inside auto layout */
    flex: none;
    order: 0;
    flex-grow: 0;
    cursor: pointer;
}

.FooterSocialAndContactContactInfo {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    padding: 0px;
    gap: 8px;
    width: 316px;
    height: 72px;
    /* Inside auto layout */
    flex: none;
    order: 1;
    flex-grow: 0;
}

.FooterSocialAndContactContactInfoNumber {
    width: 128px;
    height: 32px;
    /* Body/Body 01 (Link) */
    font-family: 'Archivo';
    font-style: normal;
    font-weight: 600;
    font-size: 18px;
    line-height: 180%;
    /* or 32px */
    /* White */
    color: #FFFFFF;
    /* Inside auto layout */
    flex: none;
    order: 0;
    flex-grow: 0;
}

.FooterSocialAndContactContactInfoEmail {
    width: 128px;
    height: 32px;
    /* Body/Body 01 (Link) */
    font-family: 'Archivo';
    font-style: normal;
    font-weight: 600;
    font-size: 18px;
    line-height: 180%;
    /* or 32px */
    /* White */
    color: #FFFFFF;
    /* Inside auto layout */
    flex: none;
    order: 0;
    flex-grow: 0;
}

.FooterColumn {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    padding: 0px;
    gap: 4px;
    width: 242px;
    height: 218px;
    /* Inside auto layout */
    flex: none;
    align-self: stretch;
    flex-grow: 1;
}

.FooterColumnText {
    width: 242px;
    height: 32px;
    /* Body/Body 01 (Link) */
    font-family: 'Archivo';
    font-style: normal;
    font-weight: 600;
    font-size: 18px;
    line-height: 180%;
    /* or 32px */
    /* White */
    color: #FFFFFF;
    /* Inside auto layout */
    flex: none;
    align-self: stretch;
    flex-grow: 0;
    cursor: pointer;
}

.AdminPage {
    margin-bottom: 50vh;
}

.DonateLinkListContainer {
    cursor: pointer;
    order: 2;
    display: flex;
    padding: 0px 24px;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    gap: 10px;
}

.DonateLinkListContainerTitle:hover {
    background-color: var(--lighter-gray, #F2F2F2);
    color: var(--orange, #E57937);
    transition: all 0.3s ease-in-out;
}

.DonateLinkListContainerTitle {
    display: flex;
    width: 499px;
    padding: 16px 8px;
    justify-content: center;
    align-items: center;
    gap: 16px;
    border-bottom: 1px solid var(--lighter-gray, #F2F2F2);
}

.DonateLinkListContainerTitleText {
    flex: 1 0 0;
    color: var(--lighter-gray, #F2F2F2);
    /* Caption/Stat Label */
    font-family: Source Sans Pro;
    font-size: 20px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    letter-spacing: 2px;
    text-transform: uppercase;
}

.DonateLinkListContainerTitle:hover .DonateLinkListContainerTitleText {
    color: var(--orange, #E57937);
}

.DonateLinkListContainerTitleArrowIcon {
    width: 20px;
    height: 20px;
    fill: var(--orange, #E57937);
}

.BannerHeader {
    display: flex;
    height: 135px;
    padding: 24px 100px;
    justify-content: center;
    align-items: center;
    gap: 80px;
    background: var(--teal, #2C97B2);
}

.BannerHeaderContent1 {
    color: var(--white, #FFF);
    /* Heading/Title 02 */
    font-family: DM Serif Text;
    font-size: 40px;
    font-style: normal;
    font-weight: 400;
    line-height: 50px;
    /* 125% */
}

.BannerHeaderContent2 {
    color: var(--white, #FFF);
    /* Body/Body 01 */
    font-family: Archivo;
    font-size: 18px;
    font-style: normal;
    font-weight: 400;
    line-height: 180%;
    /* 32.4px */
    flex: 1 0 0;
}

.DonateWithMobileDeviceSectionContainer {
    display: flex;
    padding: 60px 100px;
    flex-direction: column;
    align-items: center;
    gap: 24px;
    align-self: stretch;
}

.DonateWithMobileDeviceSectionTitle {
    align-self: stretch;
    color: var(--blue, #2A3A7E);
    text-align: center;
    /* Heading/Title 02 */
    font-family: DM Serif Text;
    font-size: 40px;
    font-style: normal;
    font-weight: 400;
    line-height: 50px;
    /* 125% */
}

.DonateWithMobileDeviceSectionText {
    align-self: stretch;
    color: var(--dark-gray, #434343);
    text-align: center;
    /* Body/Body 02 */
    font-family: Archivo;
    font-size: 20px;
    font-style: normal;
    font-weight: 400;
    line-height: 180%;
    /* 36px */
}

.QRCardsContainer {
    display: flex;
    align-items: flex-start;
    gap: 80px;
}

.QRCard {
    display: flex;
    width: 400px;
    height: 500px;
    padding: 32px;
    flex-direction: column;
    align-items: center;
    gap: 24px;
    border: 1px solid var(--medium-gray, #C3C3C3);
    background: var(--white, #FFF);
}

.QRCodeText {
    align-self: stretch;
    color: var(--dark-gray, #434343);
    text-align: center;
    /* Body/Body 02 */
    font-family: Archivo;
    font-size: 20px;
    font-style: normal;
    font-weight: 400;
    line-height: 180%;
    /* 36px */
}

.QRCodeTextContainer {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 16px;
    align-self: stretch;
}

.MoreWaysToDonateContainer {
    display: flex;
    padding: 60px 100px;
    align-items: center;
    gap: 80px;
    align-self: stretch;
    background: var(--lighter-gray, #F2F2F2);
    justify-content: center;
}

.MoreWaysToDonateText {
    max-width: 547px;
    flex: 1 0 0;
    color: var(--blue, #2A3A7E);
    /* Heading/Title 02 */
    font-family: DM Serif Text;
    font-size: 40px;
    font-style: normal;
    font-weight: 400;
    line-height: 50px;
    /* 125% */
}

.DonateViaCreditCardOrByMailContainer {
    display: flex;
    padding: 60px 100px;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 100px;
    align-self: stretch;
}

.DonateViaCreditCardOrByMailContainerCardPayment {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 24px;
}

.DonateViaCreditCardOrByMailContainerCardPaymentText {
    color: var(--blue, #2A3A7E);
    text-align: center;
    /* Heading/Title 02 */
    font-family: DM Serif Text;
    font-size: 40px;
    font-style: normal;
    font-weight: 400;
    line-height: 50px;
    /* 125% */
}

.DonateViaCreditCardOrByMailContainerMailCheckTitle {
    color: var(--blue, #2A3A7E);
    text-align: center;
    /* Heading/Title 02 */
    font-family: DM Serif Text;
    font-size: 40px;
    font-style: normal;
    font-weight: 400;
    line-height: 50px;
    margin-bottom: 15px;
    /* 125% */
}

.DonateViaCreditCardOrByMailContainerMailCheckText {
    color: var(--dark-gray, #434343);
    text-align: center;
    /* Body/Body 02 (Link) */
    font-family: Archivo;
    font-size: 20px;
    font-style: normal;
    font-weight: 700;
    line-height: 180%;
    /* 36px */
    color: var(--dark-gray, #434343);
    /* Body/Body 02 */
    font-family: Archivo;
    font-size: 20px;
    font-style: normal;
    font-weight: 400;
    line-height: 180%;
}

.DonateViaCreditCardOrByMailContainerMailCheckText p {
    margin: 0.3em 0;
    /* adjust this value as needed */
}

.ProgramBlockContainer {
    display: flex;
    padding: 60px 100px;
    justify-content: space-between;
    align-items: flex-start;
    align-self: stretch;
}

.ProgramBlockInfoContainer {
    display: flex;
    width: 610px;
    flex-direction: column;
    align-items: flex-start;
    gap: 40px;
    align-self: stretch;
}

.ProgramBlockInfoTitle {
    align-self: stretch;
    color: var(--blue, #2A3A7E);
    /* Heading/Title 03 */
    font-family: DM Serif Text;
    font-size: 36px;
    font-style: normal;
    font-weight: 400;
    line-height: 60px;
    /* 166.667% */
}

.ProgramBlockInfoText {
    align-self: stretch;
    color: var(--dark-gray, #434343);
    /* Body/Body 02 */
    font-family: Archivo;
    font-size: 20px;
    font-style: normal;
    font-weight: 400;
    line-height: 180%;
    /* 36px */
}

.ProgramBlockInfoRegionsContainer {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 16px;
    align-self: stretch;
}

.ProgramBlockInfoRegionsTitleContainer {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 8px;
    align-self: stretch;
}

.ProgramBlockInfoRegionIcon {
    width: 14px;
    height: 20px;
    fill: var(--orange, #E57937);
}

.ProgramBlockInfoRegionsBulletItems {
    color: var(--dark-gray, #434343);
    /* Body/Body 02 */
    font-family: Archivo;
    font-size: 20px;
    font-style: normal;
    font-weight: 400;
    line-height: 180%;
    /* 36px */
}

.ProgramBlockInfoRegionsTitle {
    flex: 1 0 0;
    color: var(--orange, #E57937);
    /* Caption/Stat Label */
    font-family: Source Sans Pro;
    font-size: 20px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    letter-spacing: 2px;
    text-transform: uppercase;
}

.ProgramBlockPhotoContainer {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 16px;
    align-self: stretch;
    margin-top: 15px;
}

.ProgramBlockPhotoContentContainer {
    width: 550px;
    height: 550px;
    flex-shrink: 0;
}

.ProgramBlockPhoto {
    display: flex;
    width: 550px;
    height: 282px;
    justify-content: center;
    align-items: center;
    /* background: var(--medium-gray, #C3C3C3); */
}

.CarouselImage {
    width: 550px;
    height: 282px;
    object-fit: cover;
}

.CarouselCaption {
    color: var(--dark-gray, #434343);
    /* Caption/Caption 02 */
    font-family: Archivo;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 180%;
    /* 28.8px */
}

.ProgramBlockPhotoVideoLinkContainer {
    display: flex;
    align-items: center;
    margin-top: 20px;
    gap: 24px;
    cursor: pointer;
}

.ProgramBlockPhotoVideoLinkText {
    color: var(--blue, #2A3A7E);
    /* Body/Body 02 (Link) */
    font-family: Archivo;
    font-size: 20px;
    font-style: normal;
    font-weight: 700;
    line-height: 180%;
    /* 36px */
}

.divider {
    border: 0;
    border-top: 2px solid #333;
    margin: 30px 10;
    align-self: center;
}